<template>
    <account-card :title="$t('marketingEmails')">
        <v-switch inset color="success"
                  :disabled="isSaving" :loading="isSaving" @change="changed"
                  :label="$t('label.sendMeGreatDeals')" v-model="optedIn"/>
    </account-card>
</template>

<script>
    import APICustomer from '../../api/APICustomer'
    import AccountCard from "./AccountCard";

    export default {
        name: "MarketingEmails",
        components: {AccountCard},
        data() {
            return {
                isSaving: false,
                optedIn: this.value
            }
        },
        props: {
            value: {type: Boolean}
        },
        methods: {
            changed() {
                this.$emit('input', this.optedIn);
                const me = this;
                me.isSaving = true;

                APICustomer.setMarketing(this, this.optedIn)
                    .catch(() => {
                        this.optedIn = !this.optedIn;
                    })
                    .finally(() => {
                        me.isSaving = false;
                        this.$emit('input', this.optedIn);
                    });


            }
        }
    }
</script>

<style scoped>

</style>
