<template>
    <account-card :title="$t('password')">

        <v-row v-if="enterNewPassword">
            <v-col cols="12" class="nolo-body">
                <p>{{$t('message.passwordTip')}}</p>
                <v-form v-model="validChangeForm">
                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <v-text-field outlined type="password" :label="$t('label.newPassword')"
                                          required
                                          :rules="[inputRules.required, inputRules.validPassword]"
                                          v-model="newPassword"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field outlined type="password" :label="$t('label.confirmPassword')"
                                          required
                                          :rules="[inputRules.required, validConfirmation]"
                                          v-model="confirmPassword"/>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row dense>
                    <v-col>
                        <v-btn outlined class="mr-2 mb-4" @click="cancelEditPassword">{{ $t('btn.cancel') }}</v-btn>
                        <v-btn @click="changePassword" :disabled="!validChangeForm" color="primary" :loading="working"
                               class="mb-4">{{$t('btn.updatePassword')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12" class="nolo-body">
                <p>{{$t('message.changePasswordVerifyItsYou')}}</p>
                <v-row no-gutters>
                    <v-col cols="12" sm="6">
                        <v-text-field outlined type="password" required
                                      :label="$t('label.currentPassword')" v-model="password"/>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-btn @click="validateExistingPassword" :disabled="password.length < 6" color="error"
                               :loading="working" class="ml-sm-4">{{$t('btn.editPassword')}}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col>
                        <v-alert tile :value="errorMessage !== ''" color="error" class="mt-6 mt-sm-0">{{ errorMessage }}</v-alert>
                        <v-alert tile :value="successMessage !== ''" color="success" class="mt-6 mt-sm-0">{{ successMessage }}</v-alert>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </account-card>
</template>

<script>


    import APICustomer from "../../api/APICustomer";
    import AccountCard from "./AccountCard";

    export default {
        name: "Password",
        components: {AccountCard},
        data() {
            return {
                validChangeForm: false,
                enterNewPassword: false,
                password: '',
                errorMessage: '',
                successMessage: '',
                newPassword: '',
                confirmPassword: '',
                working: false
            }
        },
        methods: {
            validateExistingPassword() {
                const me = this;
                this.successMessage = '';
                this.errorMessage = '';
                this.working = true;
                APICustomer.validatePassword(this, this.password)
                    .then(() => {
                        me.enterNewPassword = true;
                    })
                    .catch(() => {
                        me.errorMessage = this.$t('error.incorrectExistingPassword');
                    })
                    .finally(() => {
                        me.working = false;
                    })

            },
            changePassword() {
                const me = this;
                this.successMessage = '';
                this.errorMessage = '';
                this.working = true;
                APICustomer.changePassword(this, this.password, this.newPassword)
                    .then(() => {
                        me.cancelEditPassword();
                        me.successMessage = this.$t('message.passwordChanged');
                    })
                    .catch(() => {
                        me.errorMessage = this.$t('error.passwordChangeFail');
                    })
                    .finally(() => {
                        me.working = false;
                    })

            },
            cancelEditPassword() {
                this.password = '';
                this.newPassword = '';
                this.confirmPassword = '';
                this.enterNewPassword = false;
            },
            validConfirmation(v) {
                return v === this.newPassword ? true : this.$t('error.passwordDoesntMatch');
            },
        },
    }
</script>

<style scoped>

</style>
