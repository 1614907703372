<template>
    <account-card :title="$t('previousOrders')">
        <v-row no-gutters>
            <v-col cols="12" v-if="hasPreviousOrders">
                <order-list :orders="shortOrders" @order-select="viewOrder"/>
                <v-btn outlined color="secondary" @click="showMoreOrders" :disabled="loadingMore" :block="isXs"
                       v-if="hasMoreOrder"
                       :loading="loadingMore">{{ isXs ? $t('btn.showMoreOrdersShort') : $t('btn.showMoreOrders') }}
                </v-btn>
            </v-col>

            <v-col cols="12" v-else>
                {{ $t('message.noPreviousOrders') }}
            </v-col>

        </v-row>

    </account-card>
</template>


<script>
    import OrderList from "./OrderList";
    import AccountCard from "./AccountCard";

    export default {
        name: "PreviousOrders",
        components: {AccountCard, OrderList},
        data() {
            return {
                loadingMore: false,
                shorten: true,
                orderList: this.orders
            }
        },
        props: {
            orders: {type: Array}
        },
        methods: {
            showMoreOrders() {
                this.$router.push('/account/previous-orders')
            },
            viewOrder(payload) {
                this.$router.push('/account/order/' + payload.id);
            }
        },
        computed: {
            shortListLength() {
                return this.isXs ? 2 : 5;
            },
            shortOrders() {
                if (this.shorten) {
                    return this.orderList.slice(0, this.shortListLength)
                } else {
                    return this.orderList;
                }
            },
            hasMoreOrder() {
                return this.orders.length > this.shortListLength;
            },
            hasPreviousOrders() {
                return this.orders.length > 0;
            }
        }
    }
</script>

<style scoped>

</style>
