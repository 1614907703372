<template>
    <div>
        <address-list-item v-for="address in addresses" :key="address.id" :address="address" :default-address="defaultAddressId === address.address_id"/>
    </div>
</template>

<script>
    import AddressListItem from "./AddressListItem";

    export default {
        name: "AddressList",
        components: {AddressListItem},
        props: {
            defaultAddressId: { type: String, default: null },
            addresses: {type: Array, required: true}
        }
    }
</script>

<style scoped>

</style>
