<template>
    <account-card :title="$t('personalDetails')">
        <v-row no-gutters>
            <v-col class="nolo-body">
                <div>{{ customer.firstName }} {{customer.lastName}}</div>
                <div>{{ customer.email }}</div>
                <div class="pb-2">{{ customer.phoneNumber }}</div>
            </v-col>
            <v-col class="shrink">
                <router-link to="/account/personal-details" class="text-decoration-none">
                    <v-btn outlined block color="secondary">
                        <v-icon left>mdi-pencil-outline</v-icon>
                        {{$t('btn.edit')}}
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
    </account-card>
</template>

<script>
    import AccountCard from "./AccountCard";

    export default {
        name: "PersonalDetails",
        components: {AccountCard},
        props: {
            customer: {type: Object, required: true}
        }
    }
</script>

<style scoped>

</style>
