<template>
    <v-row no-gutters>
        <v-col >
            <div class="font-weight-bold">{{address.firstname}} {{address.lastname}}
                <v-icon v-if="defaultAddress" small  right color="primary">mdi-credit-card-check</v-icon></div>
            <div>{{address.address_1}} {{address.address_2}} {{address.city}} </div>
            <div class="pb-2">{{address.zone}} <span class="text-no-wrap text-uppercase">{{address.postcode}}</span></div>
        </v-col>
        <v-col class="shrink">
            <v-btn outlined block color="secondary"
            @click="editAddress"><v-icon left>mdi-pencil-outline</v-icon>{{$t('btn.edit')}}</v-btn>
        </v-col>
        <v-col cols="12">
            <v-divider class="my-4"/>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "AddressListItem",
        props: {
            address: { type: Object, required: true},
            defaultAddress: { type: Boolean, default: false}
        },
        methods: {
            editAddress() {
                this.$router.push('/account/address/' + this.address.address_id)
            }
        }
    }
</script>

<style scoped>

</style>
