<template>
    <account-card :title="$t('carDetails')">
        <v-row no-gutters>
            <v-col cols="12" class="nolo-body">
                <car-list :vehicles="vehicles" />
            </v-col>
            <v-col cols="12">
                <v-btn :block="isXs" @click="addVehicle" outlined color="secondary">{{ $t('btn.addVehicle')}}</v-btn>
            </v-col>
        </v-row>
    </account-card>
</template>

<script>
    import AccountCard from "./AccountCard";
    import CarList from "./CarList";
    export default {
        name: "CarDetails",
        components: {AccountCard, CarList},
        props: {
            vehicles: {type: Array, required: true}
        },
        methods: {
            addVehicle() {
                this.$router.push('/account/vehicle/add')
            }
        },

    }
</script>

<style scoped>

</style>
