<template>
    <div>
        <v-row no-gutters v-for="vehicle in vehicles" :key="vehicle.id">
            <v-col cols="12" class="pb-2">
                <car-list-item :vehicle="vehicle" />
                <v-divider class="my-2"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import CarListItem from "./CarListItem";

    export default {
        name: "VehicleList",
        components: {CarListItem},
        props: {
            vehicles: {type: Array, required: true}
        }
    }
</script>

<style scoped>

</style>
