<template>
    <account-card :title="$t('yourAddressBook')">
        <v-row no-gutters>
            <v-col cols="12" class="nolo-body">
                <address-list :addresses="addresses" :default-address-id="defaultAddressId"/>
            </v-col>
            <v-col cols="12">
                <v-btn outlined :block="isXs" @click="addAddress" color="secondary">{{ $t('btn.addAddress')}}</v-btn>
            </v-col>
        </v-row>
    </account-card>
</template>

<script>
    import AddressList from "./AddressList";
    import AccountCard from "./AccountCard";

    export default {
        name: "AddressBook",
        components: {AccountCard, AddressList},
        data() {
            return {
                addresses: []
            }
        },
        props: {
            value: {type: Array},
            defaultAddressId: { type: String, default: null },
        },
        methods: {
            addAddress() {
                this.$router.push('/account/address/add')
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.addresses = newValue;
                }
            }
        },
    }
</script>

<style scoped>

</style>
