<template>
  <account-card :title="$t('deleteAccount')">

    <v-row v-if="closeAccount">
      <v-col cols="12" class="nolo-body">
        <p>{{$t('message.closeAccountTip')}}</p>
        <v-row dense>
          <v-col>
            <v-btn outlined class="mr-2 mb-4" @click="cancelAccountClosure">{{ $t('btn.cancel') }}</v-btn>
            <v-btn @click="closeAccountReq" :disabled="!closeAccount" color="primary" :loading="working"
                   class="mb-4">{{$t('btn.finalConfirmCloseAccount')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" class="nolo-body">
        <p>{{$t('message.closeAccountVerifyItsYou')}}</p>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field outlined type="password" required
                          :label="$t('label.confirmPassword')" v-model="password"/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn @click="validateExistingPassword" :disabled="password.length < 6" color="error"
                   :loading="working" class="ml-sm-4">{{$t('btn.closeAccount')}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row >
          <v-col>
            <v-alert tile :value="errorMessage !== ''" color="error" class="mt-6 mt-sm-0">{{ errorMessage }}</v-alert>
            <v-alert tile :value="successMessage !== ''" color="success" class="mt-6 mt-sm-0">{{ successMessage }}</v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </account-card>
</template>

<script>


import APICustomer from "../../api/APICustomer";
import AccountCard from "./AccountCard";

export default {
  name: "CloseAccount",
  components: {AccountCard},
  data() {
    return {
      validChangeForm: false,
      closeAccount: false,
      password: '',
      errorMessage: '',
      successMessage: '',
      confirmClosure: '',
      working: false
    }
  },
  methods: {
    validateExistingPassword() {
      const me = this;
      this.successMessage = '';
      this.errorMessage = '';
      this.working = true;
      APICustomer.validatePassword(this, this.password)
          .then(() => {
            me.closeAccount = true;
          })
          .catch(() => {
            me.errorMessage = this.$t('error.incorrectPassword');
          })
          .finally(() => {
            me.working = false;
          })
    },
    closeAccountReq() {

      this.successMessage = '';
      this.errorMessage = '';
      this.working = true;

      const me = this;
      APICustomer.closeAccount(this, this.password)
          .then(() => {
            me.cancelAccountClosure();
            APICustomer.logout(this)
                .then(() => {
                  me.$store.dispatch('session/logout');
                  me.$store.commit('app/permToken', null);
                })
          })
          .catch(() => {
            me.errorMessage = this.$t('error.accountClosureFail');
          })
          .finally(() => {
            me.working = false;
          })
    },
    cancelAccountClosure() {
      this.password = '';
      this.confirmClosure = '';
      this.closeAccount = false;
    },
  },
}
</script>

<style scoped>

</style>
