<template>
        <v-row no-gutters>
            <v-col cols="12" sm="9" md="10" class="nolo-body">
                <div class="font-weight-bold">{{$t('orderFrom', {orderId: order.order_id, storeName: order.store_name }) }}</div>
                <div>
                    {{ $t('orderType.' + order.order_type)}},
                    {{ $d(new Date(order.fulfilment_datetime.replace(' ', 'T')), 'long') }}
                </div>
                <div class="pb-2">{{orderSummary}}</div>
            </v-col>
            <v-col cols="12" sm="3" md="2">
                <v-btn outlined block color="secondary"
                @click="clickedOrder">{{$t('btn.view')}}</v-btn>
            </v-col>
            <v-col cols="12">
                <v-divider class="my-4"/>
            </v-col>
        </v-row>
</template>

<script>
    export default {
        name: "OrderListItem",
        props: {
            order: { type: Object, required: true}
        },
        methods: {
            clickedOrder() {
                this.$emit('order-select', { id: this.order.order_id })
            }
        },
        computed: {
            orderSummary() {
                return this.order.summary.replace(/<small>[^<]+<\/small>/g, '');
            }
        }
    }
</script>

<style scoped>

</style>
