<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div class="nolo-h4">{{ title }}</div>
                <v-divider class="my-4"/>
                <slot></slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "AccountCard",
        data() {
            return {
            }
        },
        props: {
            title: { type: String, required: true }
        }
    }
</script>

<style scoped>

</style>
