<template>
    <page-layout-nolo :loading="loading" :title="isLoggedIn ? $t('yourAccount') : ''"
    :back="backCaption"
    >

        <v-container v-if="isLoggedIn">
            <v-card class="mb-10 elevation-8 pa-4">
                <previous-orders :orders="customer.recentOrders" />
            </v-card>
            <v-card class="mb-10 elevation-8 pa-4">
                <marketing-emails v-model="customer.marketingOptIn"/>
            </v-card>
            <v-card class="mb-10 elevation-8 pa-4">
                <address-book v-model="customer.addresses" :default-address-id="customer.addressId" />
            </v-card>
            <v-card class="mb-10 elevation-8 pa-4">
                <personal-details :customer="customer"/>
            </v-card>
            <v-card class="mb-10 elevation-8 pa-4">
                <car-details  :vehicles="customer.vehicles"/>
            </v-card>
            <v-card class="mb-10 elevation-8 pa-4">
                <password />
            </v-card>

          <v-card class="mb-10 elevation-8 pa-4">
            <close-account />
          </v-card>

            <v-row no-gutters>
                <v-col cols="12" class="px-4">
                    <v-btn outlined class="float-right" @click="logout">{{$t('btn.logout')}}</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-else>
            <v-card class="mb-10 elevation-8 pa-4">
                <login :tag-line="$t('message.loginAccount')" />
            </v-card>
        </v-container>

    </page-layout-nolo>
</template>

<script>
    import APICustomer from '../../api/APICustomer';
    import MarketingEmails from "../../components/Account/MarketingEmails";
    import PreviousOrders from "../../components/Account/PreviousOrders";
    import AddressBook from "../../components/Account/AddressBook";
    import PersonalDetails from "../../components/Account/PersonalDetails";
    import CarDetails from "../../components/Account/CarDetails";
    import Password from "../../components/Account/Password";
    import CloseAccount from "../../components/Account/CloseAccount";
    import Login from "../../components/Account/Login";

    export default {
        name: "YourAccount",
        data() {
            return {
                loading: true,
                vehicle: { type: 'Lotus Esprit', color: 'White', reg: 'SPY 007'}
            }
        },
        components: {
            Login, CloseAccount, Password, PersonalDetails, AddressBook, PreviousOrders, MarketingEmails, CarDetails},
        mounted() {
            const me = this;
            APICustomer.getCustomer(this)
                .finally(() => {
                    me.loading = false;
                })
        },
        methods: {
            logout() {
                const me = this;
                APICustomer.logout(this)
                    .then(() => {
                        me.$store.dispatch('session/logout');
                        me.$store.commit('app/permToken', null);
                    })
            }
        },
        computed: {
            customer: {
                set: function(newValue) {
                    this.$store.commit('session/customer', newValue);
                },
                get: function() {
                    return this.$store.getters['session/customer'];

                }
            },
            backCaption() {
                if (this.isAppMode) {
                    return this.$i18n.t('btn.backToOrdering')
                } else {
                    return '';
                }
            }
        }
    }
</script>

<style scoped>

</style>
