<template>
        <v-row no-gutters>
            <v-col class="pb-2">
                <div class="font-weight-bold text-uppercase" >{{vehicle.vehicle_license}}</div>
                <div>{{ vehicle.vehicle_make_model }}</div>
                <div>{{ vehicle.vehicle_colour }}</div>
            </v-col>
            <v-col class="shrink pb-2">
                <v-btn outlined block color="secondary"
                @click="editVehicle"><v-icon left>mdi-pencil-outline</v-icon>{{$t('btn.edit')}}</v-btn>
            </v-col>
        </v-row>
</template>

<script>
    export default {
        name: "CarListItem",
        props: {
            vehicle: { type: Object, required: true},
        },
        methods: {
            editVehicle() {
                this.$router.push('/account/vehicle/' + this.vehicle.vehicle_id)
            }
        }
    }
</script>

<style scoped>

</style>
