<template>
    <div>
        <order-list-item v-for="order in orders" :key="order.id" @order-select="(payload) => $emit('order-select', payload)" :order="order"/>
    </div>
</template>

<script>
    import OrderListItem from "./OrderListItem";

    export default {
        name: "OrderList",
        components: {OrderListItem},
        props: {
            orders: {type: Array, required: true}
        }
    }
</script>

<style scoped>

</style>
